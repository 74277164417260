export default {
  footerGovernorText: [
    { text: '350 N State Street #150' },
    { text: 'PO Box 142210' },
    { text: 'Salt Lake City, UT 84114-2210' },
    { text: '(801) 538-1027', link: 'tel:801-538-1027' },
    { text: 'https://gopb.utah.gov', link: 'https://gopb.utah.gov' },
  ],
  footerStateText: [
    { text: '350 N State Street #320' },
    { text: 'PO Box 145115' },
    { text: 'Salt Lake City, UT 84114-2210' },
    { text: '(801) 538-1408', link: 'tel:801-538-1408' },
    { text: 'https://senate.utah.gov', link: 'https://senate.utah.gov' },
  ],
  footerHouseOfRepsText: [
    { text: '350 N State Street #350' },
    { text: 'PO Box 145030' },
    { text: 'Salt Lake City, UT 84114-2210' },
    { text: '(801) 538-1408', link: 'tel:801-538-1408' },
    { text: 'https://house.utah.gov', link: 'https://house.utah.gov' },
  ],
  footerUtahLinksText: [
    { text: 'Utah.gov', link: 'https://www.utah.gov/' },
    { text: 'Budget', link: 'https://budget.utah.gov/' },
    { text: 'Compendium of Budget Information', link: 'https://cobi.utah.gov/2022/1/overview' },
    { text: 'Legislature', link: 'https://le.utah.gov/' },
    { text: 'Governor', link: 'https://governor.utah.gov/' },
  ],
  footerRequiredLinksText: [
    { text: 'Terms of Use', link: 'https://www.utah.gov/disclaimer.html' },
    { text: 'Privacy Policy', link: 'https://www.utah.gov/privacypolicy.html' },
    { text: 'Accessibility Policy', link: 'https://www.utah.gov/accessibility.html' },
    { text: 'Translate Utah.gov', link: 'https://www.utah.gov/translate.html' },
  ],
};
