import React from 'react';
import constants from "../constants";
import FooterColumn from './FooterColumn';
import seal from '../../static/img/Seal_of_Utah.svg';

/**
 * @param ColumnData - An array of objects, each object contains a footer text string and an optional link
 */
const Footer = () => {
  return (
    <div className='footer-container'>
      <img src={seal} alt="State of Utah seal"  className='footer-container__image' />
      <div className={'footer-container__contacts'}>
        <FooterColumn columnName={'GOVERNOR\'S OFFICE OF PLANNING & BUDGET'} columnData={constants.footerGovernorText} />
        <FooterColumn columnName={'STATE SENATE'} columnData={constants.footerStateText} />
        <FooterColumn columnName={'HOUSE OF REPRESENTATIVES'} columnData={constants.footerHouseOfRepsText} />
        <FooterColumn columnName={'LINKS'} columnData={constants.footerUtahLinksText} />
      </div>
      <div className={'footer-container__links'}>
        <hr/>
        <FooterColumn columnData={constants.footerRequiredLinksText} />
      </div>
    </div>
  );
}

export default Footer;
