import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  columnName: PropTypes.string.isRequired,
  columnData: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const defaultProps = {
}

/**
 * @param ColumnData - An array of objects, each object contains a footer text string and an optional link
 */
const FooterColumn = ({ columnName, columnData } = {}) => {
  console.log(columnData);
  return (
    <div className={'footer-column'}>
      <p className={'footer-column__title'}>{columnName}</p>
      {columnData.map(data => {
         return data.link ? <a href={data.link} className={'footer-column__links'} key={data.text}>{data.text}</a> : <p className={'footer-column__text'} key={data.text}>{data.text}</p>
        }
      )}
    </div>
  );
}

FooterColumn.propTypes = propTypes;
FooterColumn.defaultProps = defaultProps;

export default FooterColumn;
