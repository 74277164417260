import React, { useEffect } from "react";
import { render } from "react-dom";
import "../../css/index_common.scss";
import Footer from '../components/Footer';
import IntroParagraph from '../components/IntroParagraph';

// Updated CSS styles with responsive design considerations
const tableauStyle = {
  tableauPlaceholder: {
    position: 'relative',
    width: '100%', // Use 100% width for responsiveness
    height: '850px', // Initial height, you might want to adjust this or make it dynamic
    margin: '0 auto', // Center the placeholder
    maxWidth: '1300px', // Maximum width of the container
  },
  tableauViz: {
    display: 'flex', // Ensure the object is displayed
    justifyContent: 'center',
    width: '100%', // Take full width to be responsive
    minHeight: '850px', // Provide a minimum height, adjust as needed
  },
};

const App = () => {
  useEffect(() => {
    // Add the Tableau embed script to the page
    const scriptElement = document.createElement('script');
    scriptElement.src = 'https://public.tableau.com/javascripts/api/viz_v1.js';
    document.body.appendChild(scriptElement);

    // Optional: Cleanup on component unmount
    return () => {
      document.body.removeChild(scriptElement);
    };
  }, []);

  return (
    <>
      <main className="container">
        <IntroParagraph/>
        <div className='tableauPlaceholder' id='viz1698694707563' style={tableauStyle.tableauPlaceholder}>
          <noscript>
            <a href='#'>
              <img alt='Home ' src='https://public.tableau.com/static/images/Ne/NewFundingFollow-upReport2023_16949803715800/Home/1_rss.png' style={{border: 'none'}} />
            </a>
          </noscript>
          <object className='tableauViz' style={tableauStyle.tableauViz}>
            <param name='host_url' value='https%3A%2F%2Fpublic.tableau.com%2F' />
            <param name='embed_code_version' value='3' />
            <param name='site_root' value='' />
            <param name='name' value='NewFundingFollow-upReport2023_16949803715800/Home' />
            <param name='tabs' value='no' />
            <param name='toolbar' value='yes' />
            <param name='static_image' value='https://public.tableau.com/static/images/Ne/NewFundingFollow-upReport2023_16949803715800/Home/1.png' />
            <param name='animate_transition' value='yes' />
            <param name='display_static_image' value='yes' />
            <param name='display_spinner' value='yes' />
            <param name='display_overlay' value='yes' />
            <param name='display_count' value='yes' />
            <param name='language' value='en-US' />
          </object>
        </div>
      </main>
      <Footer/>
    </>
  );
};

render(<App />, document.getElementById('react'));
