import React from "react";
import {render} from "react-dom";
import "../../css/index_common.scss";
import Footer from '../components/Footer';
import seal from '../../static/img/Seal_of_Utah.svg';

const IntroParagraph = () => {
  return (
    <>
      <div className='main-image-seal'>
        <img src={seal} alt="State of Utah seal"  className='main-text-image' />
      </div>
      <div className="main-container__text">
        <h1> Measuring the Use of Utah Taxpayer Dollars </h1>
        <div className='main-container__supportTextContainer'>
          <p>
            A 2021 Utah law, <a href={'https://le.utah.gov/~2021/bills/static/HB0326.html'} target='_blank'>HB326</a>,
            formalized and expanded existing performance measurement practices for programs and projects receiving Utah
            taxpayer dollars, including requiring measurement of any programs receiving new funding of $10,000 or greater.
          </p>
          <p>
            Many state government programs have performance measures which are written into their
            <a href={'https://le.utah.gov/search.jsp?String=Appropriations&Submit=Find'} target='_blank'> appropriations bills</a>,
            which provide funding for those programs. Every year in October, programs report data for those performance measures
            to the <a href={'https://gopb.utah.gov/'} target='_blank'>Governor's Office of Planning and Budget</a> and the
            <a href={'https://le.utah.gov/lfa/index.htm'} target={'_blank'}> Office of the Legislative Fiscal Analyst.</a> These
            performance data help both the executive and legislative branches understand the work of taxpayer-funded programs and
            their impact. <a href={'https://le.utah.gov/interim/2021/pdf/00004257.pdf'} target={'_blank'}> Read more</a> about the shared process
            by which both the executive and legislative branches are tracking and evaluating state government programs together.
          </p>
          <p>
            The information below previews Utah government performance measures which will be published here during the 2022 Legislative General Session,
            including an API allowing the public to analyze these data themselves.
          </p>
          <p> Contact <a href="mailto:performance@utah.gov">performance@utah.gov</a> with questions or suggestions regarding these performance measures. </p>
        </div>
      </div>
    </>
  );
}

export default IntroParagraph;
